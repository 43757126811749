module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"thedveloper-website","defaultLang":"es-ec","langs":["es-ec","en-us"],"accessToken":"MC5YdHdhYnhJQUFDUUFTdkJL.77-977-977-977-9d--_ve-_ve-_ve-_ve-_vWrvv70BPR_vv73vv70HX--_ve-_ve-_ve-_vTDvv73vv70B77-9B--_ve-_ve-_vQ","path":"/preview","previews":true,"shortenUrlLangs":true,"pages":[{"type":"Homepage","match":"/:lang?/","previewPath":"/","component":"/opt/build/repo/src/pages/index.js","langs":["es-ec","en-us"]},{"type":"Project","match":"/:lang?/work/","previewPath":"/work","component":"/opt/build/repo/src/pages/work.js","langs":["es-ec","en-us"]},{"type":"Project","match":"/:lang?/project/:uid","previewPath":"/project","component":"/opt/build/repo/src/templates/project.jsx","langs":["es-ec","en-us"]},{"type":"Post","match":"/:lang?/blog/","previewPath":"/blog","component":"/opt/build/repo/src/pages/blog.js","langs":["es-ec","en-us"]},{"type":"Post","match":"/:lang?/post/:uid","previewPath":"/post","component":"/opt/build/repo/src/templates/post.jsx","langs":["es-ec","en-us"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Dveloper","short_name":"The Dveloper","description":"Personal website to showcase a portfolio + blog for Christian Guevara, welcome :)","start_url":"","background_color":"#EBEDF2","theme_color":"#3D63AE","display":"standalone","icon":"src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f3ef50e1c38c8ee6781f0f2013a46688"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXXXXX-X","head":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
